import { createSlice } from "@reduxjs/toolkit";
import ApiClientConnector from "src/api/ApiClientConnector";

const initialState = {
  roomDetails: {},
  hotelCost: 0,
  showRoomDetails: false,
};

const calculateTotalRoomCost = (room) => {
  const supplementsCost =
    room.SupplementResults?.reduce(
      (sum, s) => (s.Included ? sum + s.Value.Total : sum),
      0
    ) || 0;

  const discountTotal =
    room.DiscountResults?.reduce(
      (sum, discount) => (discount.Included ? sum + discount.Value.Total : sum),
      0
    ) || 0;
    
  const baseCost = room.ClientNetSell + room.ClientNetSellTax;

  return (baseCost + supplementsCost + discountTotal) * (room.RoomCount || 0);
};

const calculateTotalHotelCost = (roomRequests) => {
  return roomRequests.reduce((total, roomRequest) => {
    const requestCost = roomRequest.Rooms.reduce((roomTotal, room) => {
      return roomTotal + calculateTotalRoomCost(room);
    }, 0);
    return total + requestCost;
  }, 0);
};

export const HotelRoomDetailSlice = createSlice({
  name: "hotelRoomDetails",
  initialState,
  reducers: {
    setHotelRoomDetails: (state, action) => {
      state.roomDetails = action.payload;
      state.hotelCost = 0;
    },
    setHotelRoomDetailsVisibility(state, action) {
      state.showRoomDetails = action.payload;
    },
    updateRoomCount: (state, action) => {
      const { roomRequestId, roomTypeId, roomCount } = action.payload;

      if (roomCount !== 0 && roomCount !== 1) {
        console.warn("Room count must be either 0 or 1.");
        return;
      }

      const updatedRoomRequestResults =
        state.roomDetails?.RoomRequestResults?.map((roomRequest) => {
          if (roomRequest.RoomRequestId !== roomRequestId) return roomRequest;

          const updatedRooms = roomRequest.Rooms.map((room) => {
            if (room.RoomTypeId === roomTypeId) {
              return {
                ...room,
                RoomCount: roomCount,
                TotalCost: calculateTotalRoomCost({
                  ...room,
                  RoomCount: roomCount,
                }),
              };
            }
            return { ...room, RoomCount: 0, TotalCost: 0 };
          });

          return {
            ...roomRequest,
            Rooms: updatedRooms,
          };
        });

      state.roomDetails.RoomRequestResults = updatedRoomRequestResults;

      state.hotelCost = calculateTotalHotelCost(updatedRoomRequestResults);
    },
    updateSupplementSelection(state, action) {
      const { roomRequestId, roomTypeId, supplementReferenceId, isSelected } =
        action.payload;

      const roomRequest = state.roomDetails.RoomRequestResults.find(
        (request) => request.RoomRequestId === roomRequestId
      );

      if (roomRequest) {
        const room = roomRequest.Rooms.find((r) => r.RoomTypeId === roomTypeId);
        if (room && room.SupplementResults) {
          const supplement = room.SupplementResults.find(
            (s) => s.ReferenceId === supplementReferenceId
          );
          if (supplement) {
            supplement.Included = isSelected;
          }
          room.TotalCost = calculateTotalRoomCost(room);
        }
        // Update total hotel cost
        state.hotelCost = calculateTotalHotelCost(
          state.roomDetails.RoomRequestResults
        );
      }
    },
    updateDiscount: (state, action) => {
      const { roomRequestId, roomTypeId, selectedSelectionId } =
        action.payload;

      const roomRequest = state.roomDetails.RoomRequestResults.find(
        (request) => request.RoomRequestId === roomRequestId
      );

      if (roomRequest) {
        const room = roomRequest.Rooms.find(
          (room) => room.RoomTypeId === roomTypeId
        );
        if (room) {
          //room.AppliedDiscount = discountValue || 0;

          room.DiscountResults.forEach((discount) => {
            discount.Included = discount.SelectionId === selectedSelectionId;
          });

          room.TotalCost = calculateTotalRoomCost(room);
        }

        // Update total hotel cost
        state.hotelCost = calculateTotalHotelCost(
          state.roomDetails.RoomRequestResults
        );
      }
    },
  },
});

export const {
  setHotelRoomDetails,
  setHotelRoomDetailsVisibility,
  updateRoomCount,
  updateSupplementSelection,
  updateDiscount,
} = HotelRoomDetailSlice.actions;

export const fetchHotelRoomsDetails =
  (instance, accounts, hotelId, criteria) => async (dispatch) => {
    try {
      const client = await ApiClientConnector(instance, accounts);
      const response = await client.hotelById.post(hotelId, criteria);
      const hotelRooms = response.Result || {};

      hotelRooms.RoomRequestResults?.forEach((request) => {
        request.Rooms.forEach((room) => {
          room.RoomCount = 0;
          room.TotalCost = 0;
          room.SupplementResults?.forEach((supp) => {
            supp.Included = supp.Included ?? false;
          });
        });
      });

      dispatch(setHotelRoomDetails(hotelRooms));
    } catch (error) {
      throw error;
    }
  };

export default HotelRoomDetailSlice.reducer;
